import {
  HUDDLE_IMAGE_URL,
  LOCATION,
  USER_HUDDLE_IMAGE_URL,
} from "@/app/_consts/internal";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Location } from "@/app/_types";
import { LocationDashboardStats } from "@/app/_types/dashboard";

const initialState = {
  isLoading: true,
  locations: [
    {
      name: "",
      timezone: "",
      hospital_manager: "",
    },
  ] as Location[],
  locationStats: null as LocationDashboardStats | null,
  huddleImagePath: "",
  selectedLocation: null as Location | null,
};

export const fetchHuddleImagePath = createAsyncThunk<any, string>(
  "/images/gethuddle",
  async (accessToken) => {
    const response = await axios.get(HUDDLE_IMAGE_URL, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  },
);

export const fetchLocationHuddleImagePath = createAsyncThunk<any, string>(
  "/images/user/huddle",
  async (accessToken) => {
    const selectedLocation = localStorage.getItem("selectedLocation");
    if (selectedLocation) {
      const response = await axios.get(USER_HUDDLE_IMAGE_URL, {
        headers: { Authorization: accessToken },
        params: { location: JSON.parse(selectedLocation)["name"] },
      });

      const randomIndex = Math.floor(Math.random() * response.data.length);
      return response.data[randomIndex]["filename"];
    }
  },
);

export const fetchLocationData = createAsyncThunk<any, string>(
  "locationDashboard/fetchLocationData",
  async (accessToken, thunkAPI) => {
    try {
      const selectedLocation = localStorage.getItem("selectedLocation");

      if (selectedLocation) {
        thunkAPI.dispatch(setSelectedLocation(JSON.parse(selectedLocation)));
      }

      const response = await axios.get(LOCATION, {
        headers: { Authorization: accessToken },
      });

      const data = response.data;
      const formattedData = Object.keys(data).map((key) => ({
        name: key,
        ...data[key],
      }));

      return formattedData;
    } catch (error: any) {
      return Promise.reject(error.message);
    }
  },
);

export const fetchLocationStats = createAsyncThunk<any, any>(
  "locationDashboard/fetchLocationStats",
  async ({ location, month, accessToken }) => {
    try {
      const response = await axios.get(`${LOCATION}/stats`, {
        params: {
          location,
          month,
        },
        headers: { Authorization: accessToken },
      });
      return response.data;
    } catch (error: any) {
      return Promise.reject(error.message);
    }
  },
);

const locationDashboardSlice = createSlice({
  name: "locationDashboard",
  initialState,
  reducers: {
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
      localStorage.setItem("selectedLocation", JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocationData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLocationData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locations = action.payload;
      })
      .addCase(fetchLocationData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchLocationStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLocationStats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locationStats = action.payload;
      })
      .addCase(fetchLocationStats.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchLocationHuddleImagePath.fulfilled, (state, action) => {
        state.huddleImagePath = action.payload;
      });
  },
});

export const { setSelectedLocation } = locationDashboardSlice.actions;

export default locationDashboardSlice.reducer;
