import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { DAILY_ARTICLE_URL, USER_HUDDLES } from "@/app/_consts/internal";
import { fetchDailyArticle } from "./dailyArticleSlice";
import { UserHuddleData } from "@/app/_types";

const initialState: UserHuddleData = {
  isLoading: true,
  allUserHuddleCount: 0,
  allUserCount: 0,
  huddles: {},
};

export const fetchUserHuddles = createAsyncThunk<any, string>(
  "userHuddles/fetchUserHuddles",
  async (accessToken) => {
    const response = await axios.get(USER_HUDDLES, {
      headers: { Authorization: accessToken },
    });
    return response.data;
  },
);

export const saveUserHuddled = createAsyncThunk<any, string>(
  "dailyArticle/saveUserHuddled",
  async (accessToken) => {
    const response = await await axios.put(`${DAILY_ARTICLE_URL}/huddle`, "", {
      headers: { Authorization: accessToken },
    });
    const newHuddles = response.data;
    return newHuddles;
  },
);

const userHuddlesSlice = createSlice({
  name: "userHuddles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserHuddles.pending, (state) => {
        state.isLoading = true;
        state.huddles = {};
      })
      .addCase(fetchUserHuddles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.huddles = action.payload;
      })
      .addCase(fetchUserHuddles.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchDailyArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allUserHuddleCount = parseInt(action.payload.huddleCount);
        state.allUserCount =
          parseInt(action.payload.startUserCount) +
          parseInt(action.payload.newUserCount);
      })
      .addCase(saveUserHuddled.fulfilled, (state, action) => {
        if (typeof action?.payload?.huddles_item === "object") {
          state.huddles = action.payload.huddles_item;
        }
        if (typeof action?.payload?.daily_stats_item === "object") {
          state.allUserHuddleCount =
            action.payload.daily_stats_item.huddleCount;
        }
      });
  },
});

export default userHuddlesSlice.reducer;
