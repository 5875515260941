"use client";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedLocation } from "../../reducers/locationDashboardSlice";
import { RootState } from "../../reducers/store";
import { Location } from "@/app/_types";
import LocationIcon from "../graphics/LocationIcon";
import SearchAutocomplete from "./SearchAutocomplete";

interface LocationSearchInputProps {
  initialValue?: string;
  autoFocus?: boolean;
  additionalFilter?: (item: Location) => boolean;
  onSelectLocation?: (location: Location) => void;
  includeAll?: boolean;
}

const LocationSearchInput: React.FC<LocationSearchInputProps> = ({
  initialValue,
  autoFocus,
  additionalFilter,
  onSelectLocation,
  includeAll,
}) => {
  const dispatch = useDispatch();
  const { locations, selectedLocation } = useSelector(
    (state: RootState) => state.locationDashboard,
  );
  const allLocations = {
    name: "All Locations",
    timezone: "",
    hospital_manager: "",
  };

  const handleSelectLocation = async (location: Location) => {
    if (onSelectLocation) {
      onSelectLocation(location);
    } else {
      await dispatch(setSelectedLocation(location));
    }
  };

  const sortedLocations = [...locations].sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  if (includeAll) {
    sortedLocations.unshift(allLocations);
  }

  return (
    <div className="flex w-full items-center overflow-visible">
      <SearchAutocomplete<Location>
        data={sortedLocations}
        valueKey="name"
        onSelect={handleSelectLocation}
        placeholder="Search for a location"
        autoFocus={autoFocus}
        additionalFilter={additionalFilter}
        icon={<LocationIcon />}
        isDropdown={true}
        keepValueOnFocusChange={true}
        initialValue={
          typeof initialValue === "string"
            ? initialValue
            : selectedLocation
              ? selectedLocation.name
              : ""
        }
      />
    </div>
  );
};

export default LocationSearchInput;
